import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ziggurat-web/ziggurat-web/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Welcome to the ziggurat wiki!`}</p>
    <img src="https://ziggurat.dev/static/ee4981682d08db3bbd29a7279bf2d8f3/34167/logo-ziggurat.png"></img>
    <p>{`Ziggurat is a framework built to simplify Multi-Stream processing on Kafka. It can be used to create a full-fledged Clojure app that reads and processes messages from Kafka.
Ziggurat is built with the intent to abstract out`}</p>
    <pre><code parentName="pre" {...{}}>{`- reading messages from Kafka
- retrying failed messages
- setting up an HTTP server
`}</code></pre>
    <p>{`from a clojure application such that a user only needs to pass a function that will be mapped to every message recieved from Kafka.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      